import { gql } from '@apollo/client';

export const GET_PUBLIC_MATERIALS = gql`
  query getPublicMaterials(
    $authorId: ID
    $authorName: String
    $categoriesIds: [Int!]
    $ids: [ID!]
    $isPublic: Boolean
    $kind: String
    $orderAttributes: JSON
    $page: Int
    $perPage: Int
    $tags: [String!]
    $title: String
  ) {
    getPublicMaterials(
      authorId: $authorId
      authorName: $authorName
      categoriesIds: $categoriesIds
      ids: $ids
      isPublic: $isPublic
      kind: $kind
      orderAttributes: $orderAttributes
      page: $page
      perPage: $perPage
      tags: $tags
      title: $title
    ) {
      page
      pageCount
      perPage
      totalCount
      publicMaterials {
        authorName
        categories {
          id
          name
          slug
          title
        }
        covers {
          filename
          url
        }
        createdAt
        id
        isPublic
        kind
        previewText
        readingTime
        slug
        tags
        title
        viewsCount
      }
    }
  }
`;

export const GET_PUBLIC_VARIANTS_MATERIALS = gql`
  query getPublicMaterials(
    $kind: String
    $orderAttributes: JSON
    $page: Int
    $perPage: Int
    $tags: [String!]
    $title: String
  ) {
    getPublicMaterials(
      kind: $kind
      orderAttributes: $orderAttributes
      page: $page
      perPage: $perPage
      tags: $tags
      title: $title
    ) {
      publicMaterials {
        categories {
          slug
        }
        id
        slug
        title
        viewsCount
      }
    }
  }
`;

export const GET_PUBLIC_MATERIALS_STATISTIC = gql`
  query getPublicMaterials(
    $kind: String
    $page: Int
    $perPage: Int
  ) {
    getPublicMaterials(
      kind: $kind
      page: $page
      perPage: $perPage
    ) {
      totalCount
      totalViewsCount
      totalLikesCount
    }
  }
`;

export const GET_PUBLIC_MATERIAL = gql`
  query getPublicMaterial($id: ID, $slug: String) {
    getPublicMaterial(id: $id, slug: $slug) {
      authorName
      body
      canSendProposal
      categories {
        id
        name
        slug
        title
      }
      covers {
        filename
        url
      }
      createdAt
      description
      id
      isBoldTitleText
      isPublic
      kind
      likesCount
      previewText
      readingTime
      seoKeywords
      seoTitle
      slug
      tags
      title
      updatedAt
      viewsCount
    }
  }
`;

export const ADD_VIEW_PUBLIC_MATERIAL = gql`
  mutation addViewToPublicMaterial($id: ID!) {
    addViewToPublicMaterial(id: $id) {
      success
      errors {
        message
        objectField
        valid
      }
    }
  }
`;

export const GET_PUBLIC_MATERIAL_CATEGORIES = gql`
  query getCategories {
    getCategories {
      children {
        description
        id
        kind
        landCode
        name
        position
        slug
        title
      }
      description
      id
      kind
      landCode
      name
      position
      slug
      title
    }
  }
`;

export const GET_PUBLIC_LESSON = gql`
  query getPublicMaterial($id: ID, $slug: String) {
    getPublicMaterial(id: $id, slug: $slug) {
      categories {
        id
        name
        slug
        title
      }
      id
      kind
      slug
      tags
      updatedAt

      publicLesson {
        classroomWorkCards {
          cardType
          data
          id
          order
          mediaFiles {
            contentType
            url
          }
        }
        course {
          icon {
            url
          }
          id
          title
        }
        holdTestCards {
          cardType
          data
          id
          order
          mediaFiles {
            id
            url
          }
        }
        subject {
          icon {
            url
          }
          id
          name
          shortName
        }
        title
        topic {
          id
          title
        }
      }

      lessonId
    }
  }
`;

export const MANAGE_REACTION_PUBLIC_MATERIAL = gql`
  mutation manageReactionPublicMaterial(
    $action: String!
    $publicMaterialId: ID!
    $reaction: ReactionEnum!
  ) {
    manageReactionPublicMaterial(
      action: $action
      publicMaterialId: $publicMaterialId
      reaction: $reaction
    ) {
      success
      errors {
        message
        objectField
        valid
      }
    }
  }
`;
