import { gql } from '@apollo/client';

export const USER = gql`
  fragment userData on User {
    avatarId
    avatar {
      url
    }
    currentTime
    educationType
    email
    emailMe
    id
    name
    paidSchool
    parentPhone
    patronymic
    phone
    secondName

    paidProducts {
      amount
      classNumber
      duration
      premiumEndedAt
      premiumStartedAt
      status
      title
      educationType
    }
  }
`;

export const USER_STUDENT = gql`
  fragment studentData on Student {
    avatarId
    avatar {
      url
    }
    classNumber
    currentTime
    educationType
    email
    emailMe
    enrolled
    id
    name
    paidSchool
    parentPhone
    patronymic
    phone
    registrationLeadId
    secondName

    paidProducts {
      amount
      classNumber
      duration
      premiumEndedAt
      premiumStartedAt
      status
      title
      educationType
    }
  }
`;

export const USER_TEACHER = gql`
  fragment teacherData on Teacher {
    id
    email
    name
    avatar {
      url
    }
    phone
    avatarId
    currentTime
    secondName
    patronymic
  }
`;

export const USER_TEACHER_CURATOR = gql`
  fragment teacherCuratorData on TeacherCurator {
    avatarId
    avatar {
      url
    }
    email
    id
    name
    patronymic
    phone
    secondName
  }
`;

export const USER_SCHOOL_ADMIN = gql`
  fragment schoolAdminData on SchoolAdmin {
    id
    email
    name
    avatar {
      url
    }
    phone
    avatarId
    currentTime
    secondName
    patronymic
  }
`;

export const USER_PARENT = gql`
  fragment parentData on Parent {
    avatarId
    avatar {
      url
    }
    currentTime
    educationType
    email
    emailMe
    id
    name
    paidSchool
    parentPhone
    patronymic
    phone
    secondName

    paidProducts {
      amount
      classNumber
      duration
      premiumEndedAt
      premiumStartedAt
      status
      title
      educationType
    }
  }
`;

export const GET_ME = gql`
  query me {
    me {
      student {
        ...studentData
      }
      teacher {
        ...teacherData
      }
      teacherCurator {
        ...teacherCuratorData
      }
      schoolAdmin {
        ...schoolAdminData
      }
      parent {
        ...parentData
      }
    }
  }
  ${USER_STUDENT}
  ${USER_TEACHER}
  ${USER_TEACHER_CURATOR}
  ${USER_SCHOOL_ADMIN}
  ${USER_PARENT}
`;

export const LOGIN = gql`
  mutation signIn(
    $email: String!
    $fingerprint: String!
    $password: String!
  ) {
    signIn(
      email: $email
      fingerprint: $fingerprint
      password: $password
    ) {
      student {
        ...studentData
      }
      teacher {
        ...teacherData
      }
      teacherCurator {
        ...teacherCuratorData
      }
      schoolAdmin {
        ...schoolAdminData
      }
      parent {
        ...parentData
      }
      user {
        ...userData
      }
      success
      errors {
        message
      }
    }
  }
  ${USER_STUDENT}
  ${USER_TEACHER}
  ${USER_TEACHER_CURATOR}
  ${USER_SCHOOL_ADMIN}
  ${USER_PARENT}
  ${USER}
`;

export const REGISTER = gql`
  mutation signUp(
    $classNumber: Int
    $comment: String
    $email: String!
    $emailMe: Boolean
    $fingerprint: String!
    $formTitle: String
    $landCode: String
    $name: String!
    $phone: String!
    $type: String!
    $utmData: JSON
    $visitHistory: [JSON!]
    $password: String
  ) {
    signUp(
      classNumber: $classNumber
      comment: $comment
      email: $email
      emailMe: $emailMe
      fingerprint: $fingerprint
      formTitle: $formTitle
      landCode: $landCode
      name: $name
      phone: $phone
      type: $type
      utmData: $utmData
      visitHistory: $visitHistory
      password: $password
    ) {
      success
      errors {
        message
        objectField
        valid
      }
      user {
        ...userData
      }
    }
  }
  ${USER}
`;

export const REMEMBER = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email, refferer: "school") {
      errors {
        field
        message
      }
      success
    }
  }
`;

/** ** Mutations **** */

export const LOGOUT = gql`
  mutation signOut($fingerprint: String!) {
    signOut(fingerprint: $fingerprint) {
      success
    }
  }
`;

export const SEND_LEAD = gql`
  mutation sendLead(
    $blankRegistration: Boolean,
    $email: String
    $formTitle: String
    $landCode: String!
    $latestComment: String
    $name: String
    $phone: String
    $utmData: JSON
    $visitHistory: [JSON!]
  ) {
    sendLead(
      blankRegistration: $blankRegistration
      email: $email
      formTitle: $formTitle
      landCode: $landCode
      latestComment: $latestComment
      name: $name
      phone: $phone
      utmData: $utmData
      visitHistory: $visitHistory
    ) {
      success
      errors {
        objectField
        message
        valid
      }
      lead {
        id
      }
    }
  }
`;

export const FINALIZE_LEAD = gql`
  mutation finalizeLead(
    $comment: String!
    $email: String!
    $formTitle: String
    $id: ID!
    $name: String!
    $phone: String!
    $visitHistory: [JSON!]
  ) {
    finalizeLead(
      comment: $comment
      email: $email
      formTitle: $formTitle
      id: $id
      name: $name
      phone: $phone
      visitHistory: $visitHistory
    ) {
      success
      errors {
        objectField
        message
        valid
      }
      lead {
        id
      }
    }
  }
`;

export const UPDATE_LEAD = gql`
  mutation updateLead(
    $id: ID!,
    $comment: String!,
  ) {
    updateLead(
      id: $id,
      comment: $comment,
    ) {
      success
      errors {
        objectField
        message
        valid
      }
    }
  }
`;

export const PURCHASE_PRODUCT = gql`
  mutation purchaseProduct(
    $callbackSuccess: String!
    $productId: ID!
    $promoCode: String
    $startTrial: Boolean
  ){
    purchaseProduct (
      callbackSuccess: $callbackSuccess
      productId: $productId
      promoCode: $promoCode
      startTrial: $startTrial
    ) {
      errors {
        objectField,
        message,
        valid
      },
      success,
      link
    }
  }`;

export const EMAIL_SUBSCRIBE = gql`
  mutation sendInfoToEnkod(
    $email: String!
  ){
    sendInfoToEnkod (
      email: $email
    ) {
      errors {
        objectField,
        message,
        valid
      },
      success,
    }
  }`;

export const CONFIRM_EMAIL = gql`
  mutation confirmEmail($confirmationToken: String!){
    confirmEmail(confirmationToken: $confirmationToken) {
      errors {
        field
        message
        valid
      }
      success
    }
  }
`;

export const GET_BANNERS = gql`
  query getMarketingContents(
    $categoryIds: [ID!]
    $contentType: String
    $page: Int
    $perPage: Int
    $published: Boolean
  ) {
    getMarketingContents(
      categoryIds: $categoryIds
      contentType: $contentType
      page: $page
      perPage: $perPage
      published: $published
    ) {
      marketingContents {
        categories {
          id
          ancestry
          children {
            id
          }
        }
        data
        footer
        general
        header
        id
        images {
          url
        }
      }
    }
  }
`;

export const GET_ONBOARDING_QUESTIONS = gql`
  query getOnboardingQuestions ($questionnarieName: String!) {
    getOnboardingQuestions (questionnarieName: $questionnarieName) {
      group
      name
      position
      text
      questionnarieName
    }
  }
`;
