const SESSION_STORAGE_KEY = 'utm';

export const saveUtm = (route) => {
  if (!process.client) return;

  const queryKeys = Object.keys(route?.query || {});

  if (queryKeys.length) {
    const haveUtmData = queryKeys.some((queryKey) => /^utm_/.test(queryKey));

    if (haveUtmData) {
      localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(route.query));
    }
  }
};

export const getUtm = () => {
  if (!process.client) return {};

  const utm = localStorage.getItem(SESSION_STORAGE_KEY);
  if (utm) {
    return JSON.parse(utm);
  }
  return {};
};

export const removeUtm = () => {
  if (!process.client) return;
  localStorage.removeItem(SESSION_STORAGE_KEY);
};

export default { saveUtm, getUtm, removeUtm };
